import '@/styles/create-account-provider.less';
import { Button, Modal } from 'antd';
import { createContext, useContext, useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import Image from 'next/image';
import { StoreSignupDialog } from '@/components/Common';
import MeshBackgroundWithNoise from '@/components/Common/MeshBackgroundWithNoise';
import { ANONYMOUS_USER_ID, CAMPAIGN_DATA } from '@/shared/constants';
import storage from '@/shared/utils/storage';
import { CheckOutlined } from '@/components/Common/Icons';
import { RegisterCustomPackagingParams } from '@/shared/types/featured-packaging';
import { isEmpty } from 'lodash';
import { logCommonView, sendEventAnalyses } from '@/lib/service';
import { EventName, EventSource } from '@/shared/constants/event';
import { useConfigProvider } from '@/lib/context/ConfigProvider';
import { setAnonymousUser } from '@/shared/utils/utils';

interface showSignupDialogCustomProps {
  description: string;
  redirectPath: string;
}

type ProviderType = {
  showSignupDialog?: () => void;
  showSendEmailSignupDialog?: ({
    link,
    customPackagingParams,
  }: {
    link?: string;
    customPackagingParams?: Partial<RegisterCustomPackagingParams>;
  }) => void;
  showSendEmailModal?: ({ url }: { url: string }) => void;
  showSignupDialogFromSourcing?: (
    description: string,
    redirectPath: string,
    utmMedium: string,
    utmSource?: string,
  ) => void;
  showSignupDialogCustom?: ({ description, redirectPath }: showSignupDialogCustomProps) => void;
  hideSignupDialog?: () => void;
  isOpen?: boolean;
};

const Context = createContext<ProviderType>({});

const CreateAccountProvider = (props: any) => {
  const { children } = props;
  const { isMobile } = useConfigProvider();

  const [open, openToggle] = useToggle(false);
  const [openSendEmailModal, setOpenSendEmailModal] = useState<boolean>(false);
  const [hideLogInLink, setHideLogInLink] = useState<boolean>(false);
  const [signupSubtitle, setSignupSubtitle] = useState<string>();
  const [signupRedirect, setSignupRedirect] = useState<string>();
  const [docLink, setDocLink] = useState<string>();
  const [loginUrl, setLoginUrl] = useState<string>();
  const [packagingParams, setPackagingParams] = useState<Partial<RegisterCustomPackagingParams>>(null);

  const showSignupDialog = () => {
    openToggle(true);
  };

  const showSendEmailSignupDialog = ({
    link,
    customPackagingParams,
  }: {
    link?: string;
    customPackagingParams?: Partial<RegisterCustomPackagingParams>;
  }) => {
    if (!isEmpty(customPackagingParams)) {
      setPackagingParams(customPackagingParams);
    }
    if (!isEmpty(link)) {
      setDocLink(link);
    }
    setSignupSubtitle(
      'In order to keep your uploaded files secure and access your quote again, please give us some information.',
    );
    setHideLogInLink(true);
    openToggle(true);
  };

  const showSendEmailModal = ({ url }: { url: string }) => {
    setOpenSendEmailModal(true);
    setLoginUrl(url);
  };

  const hideSignupDialog = () => {
    openToggle(false);
    setHideLogInLink(false);
    setDocLink(null);
    storage.del(CAMPAIGN_DATA);
  };

  const showSignupDialogFromSourcing = (
    description: string,
    redirectPath: string,
    utmMedium: string,
    utmSource = 'public_sourcing_marketplace',
  ) => {
    showSignupDialog();
    setSignupSubtitle(description);
    setSignupRedirect(redirectPath);
    storage.set(CAMPAIGN_DATA, {
      campaignSource: utmSource,
      campaignMedium: utmMedium,
    });
  };

  const showSignupDialogCustom = ({ description, redirectPath }: showSignupDialogCustomProps) => {
    showSignupDialog();
    setSignupSubtitle(description);
    setSignupRedirect(redirectPath);
  };

  const exposed = {
    showSignupDialog,
    showSendEmailSignupDialog,
    showSignupDialogFromSourcing,
    showSignupDialogCustom,
    hideSignupDialog,
    showSendEmailModal,
    isOpen: open,
  };

  const renderSidebar = (
    <div className="sidebar">
      <MeshBackgroundWithNoise />
      <div className="container">
        <div className="value-prop">
          <Image width={32} height={32} src="/images/signup/vetted-factory-icon.svg" alt="vetted factories" />
          <span>Build with 1300+ suppliers</span>
        </div>
        <div className="value-prop">
          <Image width={32} height={32} src="/images/signup/fulfillment-icon.svg" alt="fulfillment" />
          <span>Save with low cost fulfillment</span>
        </div>
        <div className="value-prop">
          <Image width={32} height={32} src="/images/signup/marketing-icon.svg" alt="marketing" />
          <span>Grow with marketing tools</span>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (open) {
      sendEventAnalyses({
        eventName: EventName.COMMON_VIEW,
        commonType: EventSource.LANDING_PAGE,
        commonContent: 'landing_page_register',
        sourceClient: isMobile ? 'mobile_web' : 'desktop_web',
        field1: 'landing_page_register_modal',
        anonymousUserId: storage.get(ANONYMOUS_USER_ID) ?? setAnonymousUser(),
      });
    }
  }, [isMobile, open]);

  return (
    <Context.Provider value={exposed}>
      {children}
      {open && (
        <Modal
          width={hideLogInLink ? 580 : 850}
          centered
          closable={false}
          title={false}
          footer={null}
          destroyOnClose
          wrapClassName="designer-register-modal"
          open={open}
          onOk={hideSignupDialog}
          onCancel={hideSignupDialog}
        >
          {!hideLogInLink && renderSidebar}
          <StoreSignupDialog
            showCloseIcon={true}
            docLink={docLink}
            hideLogInLink={hideLogInLink}
            customPackagingParams={packagingParams}
            subtitle={signupSubtitle}
            signupRedirect={signupRedirect}
          />
        </Modal>
      )}
      <Modal
        title={null}
        footer={null}
        width={640}
        open={openSendEmailModal}
        centered
        destroyOnClose
        maskClosable={false}
        rootClassName="send-email-modal-wrap"
        onCancel={() => {
          setOpenSendEmailModal(false);
        }}
      >
        <div className="icon-wrap">
          <CheckOutlined color={'#fff'} size={2.6} />
        </div>
        <div className="text">A Pietra rep will reach out to you via email regarding a quote for your project</div>
        <div className="text">In the meantime, explore our 300k+ products and 1300+ suppliers.</div>
        <Button
          block
          type="primary"
          onClick={() => {
            setOpenSendEmailModal(false);
            window.location.href = loginUrl;
          }}
        >
          Take me to Pietra
        </Button>
      </Modal>
    </Context.Provider>
  );
};

export const useCreateAccountProvider = () => useContext(Context);

export default CreateAccountProvider;
